/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-10 17:07:50
 * @Module: 认证步骤条
 */
 <template>
  <div class="authStep">
    <div class="item" :class="{active:step>=1}">
      <div class="num">1</div>
      <div class="tit">
        <p class="tit-t">{{type=='auth'?'个人':'企业'}}实名信息</p>
      </div>
    </div>
    <div class="solid"></div>
    <div class="item" :class="{active:step>=2}">
      <div class="num">2</div>
      <div class="tit">
        <p class="tit-t">扫码授权认证</p>
      </div>
    </div>
    <div class="solid"></div>
    <div class="item" :class="{active:step>=3}">
      <div class="num">3</div>
      <div class="tit">
        <p class="tit-t">认证结果</p>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    step: {
      type: Number,
      default: 1
    },
    type:{
      type:String,
      default:'auth'
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.authStep {
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 187px;
  height: 80px;
  .item {
    display: flex;
    .num {
      width: 22px;
      height: 22px;
      background: #f7f8f9;
      border: 1px solid #e3e3e3;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      border-radius: 22px;
    }
    .tit {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      margin-left: 8px;
      position: relative;
    }
  }
  .active {
    .num {
      background: #0062ff;
      color: #fff;
    }
  }
  .solid {
    height: 1px;
    background: #d9d9d9;
    flex: 1;
    margin: 0 16px;
  }
}
</style>